import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import {
  Form,
  Group,
  Switch,
  Tooltip,
  Modal,
  Row,
  Col,
  PageHeader,
  Icon,
  Tag,
  Rate,
  Card,
  List,
  Avatar,
  Collapse,
  Input,
  Radio,
  Select,
  Button,
  Divider,
  Checkbox,
  Empty,
  DatePicker,
  AutoComplete,
  InputNumber,
  Skeleton,
  notification,
  Typography,
  Tabs,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
import moment from "moment";
import ReactMarkdown from "react-markdown/with-html";
import master from "../../../../../images/payment/master.png";
import instant from "../../../../../images/payment/instant.png";
import fpx from "../../../../../images/payment/fpx.png";
import warning from "../../../../../images/warning.png";
import red_luggage from "../../../../../images/red-luggage-protection.jpeg";
import { Link, withRouter } from "react-router-dom";
import countryList from "react-select-country-list";
import qs from "qs";
import API from "../../../../api";
import flightImg from "../../../../../images/flight.png";
import PaymentMethod from "../payment";
import PriceDetails from "../priceDetails";
import Humanize from "humanize-string";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import CopyToClipboardTooltip from "../../../copyToClipboardTooltip";

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "DD MMM YYYY";

function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}

function thingsToKnowShort(string) {
  if (string.length > 25) return string.substring(0, 25) + "...";
  else return string;
}

class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Modal state
      is_cruise: false,
      moreInfoModal: false,
      redLuggageInfoModal: false,
      moreInfoModalcontent: "",
      redLuggageInfoModalcontent: "",
      latestInfoModal: false,
      freeTravelInsuranceModal: false,
      latestInfoModalcontent: "",
      bookingConfirmModal: false,
      confirmLoading: false,
      confirmCheck: [],
      bookingErrorModal: false,
      bookingErrorMessage: "",
      confirm_button_disabled: true,
      // Payment state
      method_visible: false,
      payment_type: "",
      payment_method: null,
      bank_type: null,
      redirect_url: null,
      check_out_disabled: false,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: "",
      fpxb2b_banklist: [],
      dobw_banklist: [],
      b2b_offline_banklist: "",
      banklist_select: [],
      // credits: 0,
      // Data State
      designations: homepage_new.designations,
      quantity: 1,
      seats: "",
      sub_sale_rep: "",
      tour_id: "",
      detail: [],
      passengers: [],
      validationErrors: [],
      total_adult_twin: 0,
      total_child_with_bed: 0,
      total_child_no_bed: 0,
      total_single_supp: 0,
      total_infant: 0,
      agree: "",
      fare_type: "",
      fare_type_selected: "",
      set_amount: 0,
      showInfantField: false,
      suka_insurance: null,
      prices: [],
      infant_prices: [],
      itineraryModalVisible: false,
      itineraryModalContent: "",
      numPages: null,
      page_width: 1000,
    };
  }

  priceFormat(value) {
    return value === undefined
      ? 0
      : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  onDocumentLoadSuccess = async (pdf_doc) => {
    const page = await pdf_doc.getPage(1);
    console.log(page.view[2]); // Width
    console.log(page.view[3]); // Height
    this.setState({
      numPages: pdf_doc["_pdfInfo"]["numPages"],
      page_width: page.view[2] + 50,
    });
  };

  handleCallback = (key) => {
    this.props.handleCallback(key);
    // Reset the payment method value when user clicked the Tour
    this.setState({
      payment_type: "",
      banklist_select: [],
      method_visible: false,
      payment_method: null,
      bank_type: null,
    });
  };

  handleQuantity = (value, infant_value) => {
    this.props.handleQuantity(value, infant_value);
  };

  handlePaymentType = (value, fare_type_selected) => {
    this.props.handlePaymentType(value, fare_type_selected);
  };

  handleExtraDeposit = (value) => {
    this.props.handleCustomerDeposit(value);
  };

  handleSukaInsurance = (value) => {
    this.setState({
      suka_insurance: value,
    });
  };

  /****************************** onChangeRemark *******************************/
  onChangeData = (index, type, value, e = "") => {
    if (type == "sub_sale_rep") {
      this.setState({
        sub_sale_rep: value,
      });
    } else if (type == "fare_type_selected") {
      this.setState(
        {
          fare_type_selected: value,
          set_amount: 0,
        },
        () => {
          this.handleExtraDeposit(value);
        }
      );
    } else if (type == "set_amount") {
      this.setState({
        set_amount: value,
      });
    } else if (type == "quantity") {
      let max_booking_seats =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].max_booking_seats;
      let price_type_index = 0;
      let total_seats =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .seats;
      let prices = [];
      let infant_prices = [];
      let infant_price =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .infant;
      let infant_dta =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .dta_infant;

      for (var i = 0; i < max_booking_seats; i++) {
        if (i < value) {
          this.state.passengers[i]["visible"] = true;
          // Reset category to "adult" if the selected option is not "adult" or "child_twin"
          if (
            this.state.passengers[i]["category"] === "adult" ||
            this.state.passengers[i]["category"] === "single_supplement" ||
            this.state.passengers[i]["category"] === "child_twin" ||
            this.state.passengers[i]["category"] === "child_with_bed" ||
            this.state.passengers[i]["category"] === "child_no_bed"
          ) {
            this.state.passengers[i]["category"];
          } else {
            this.state.passengers[i]["category"] = "adult";
          }
          if (
            this.state.passengers[i] &&
            this.state.passengers[i].visible === true
          ) {
            if (
              this.state.passengers[i]["category"] == "adult" ||
              this.state.passengers[i]["category"] == "single_supplement"
            ) {
              // this.state.passengers[i].showInfants = true;
              this.state.passengers[i].showInfantLink = true;
            } else {
              this.state.passengers[i].showInfants = false;
              this.state.passengers[i].showInfantLink = false;
              this.state.passengers[index].infants = [];
            }
            let found_price = prices.find(
              (x) =>
                x.name === this.state.passengers[i].category &&
                x.type ===
                  this.state.passengers[i].price_types.find(
                    (x) => x.name === this.state.passengers[i].category
                  ).type
            );
            if (found_price === undefined) {
              prices.push({
                name: this.state.passengers[i].category,
                type: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).type,
                quantity: 1,
                price: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).price,
                dta: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).dta,
              });
            } else {
              found_price.quantity += 1;
            }

            // infant prices
            if (this.state.passengers[i].infants.length > 0) {
              let found_infant = infant_prices.find(
                (x) =>
                  x.name === "infant" &&
                  x.type ===
                    this.state.passengers[i].price_types.find(
                      (x) => x.name === this.state.passengers[i].category
                    ).type
              );
              if (found_infant === undefined) {
                infant_prices.push({
                  name: "infant",
                  type: this.state.passengers[i].price_types.find(
                    (x) => x.name === this.state.passengers[i].category
                  ).type,
                  quantity: this.state.passengers[i].infants.length,
                  price: infant_price,
                  dta: infant_dta,
                });
              } else {
                found_infant.quantity +=
                  this.state.passengers[i].infants.length;
              }
            }
          }
        } else {
          this.state.passengers[i]["visible"] = false;
          // Reset category to "adult" if the selected option is not "adult" or "child_twin"
          if (
            this.state.passengers[i]["category"] === "adult" ||
            this.state.passengers[i]["category"] === "child_twin" ||
            this.state.passengers[i]["category"] === "single_supplement"
          ) {
            this.state.passengers[i]["category"];
          } else {
            this.state.passengers[i]["category"] = "adult";
          }
        }
      }

      let total_adult_twin = 0;
      let total_child_with_bed = 0;
      let total_child_no_bed = 0;
      let total_single_supp = 0;
      let total_infant = 0;
      prices
        .filter(
          (x) => x.name.includes("adult") || x.name.includes("child_twin")
        )
        .map((price) => {
          total_adult_twin += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("child_with_bed"))
        .map((price) => {
          total_child_with_bed += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("child_no_bed"))
        .map((price) => {
          total_child_no_bed += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("single_supplement"))
        .map((price) => {
          total_single_supp += price.quantity;
        });
      infant_prices
        .filter((x) => x.name.includes("infant"))
        .map((price) => {
          total_infant += price.quantity;
        });

      this.setState(
        {
          // detail: this.props.dataList,
          passengers: this.state.passengers,
          quantity: value,
          total_adult_twin: total_adult_twin,
          total_child_with_bed: total_child_with_bed,
          total_child_no_bed: total_child_no_bed,
          total_single_supp: total_single_supp,
          total_infant: total_infant,
          prices: prices,
          infant_prices: infant_prices,
          // });
        },
        () => {
          this.handleQuantity(prices, infant_prices);
        }
      );
      // if (type == "quantity"){
      //   this.setState({
      //     // detail: this.props.dataList,
      //     passengers: this.state.passengers,
      //     prices: prices,
      //     quantity: value
      //   }, () => { this.handleQuantity(prices) });
      // } else {
      //   console.log(this.state.passengers[index], "this.state.passengers[i]")
      //   this.state.passengers[index][type] = value;
      //   this.setState({
      //     passengers: this.state.passengers,
      //     prices: prices
      //   }, () => { this.handleQuantity(prices) });
      // }
    } else {
      let prices = [];
      let infant_prices = [];
      let price_type_index = 0;
      let infant_price =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .infant;
      let infant_dta =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .dta_infant;

      this.state.passengers[index][type] = value;
      /** 13 June 2024 commented out by Stefan, to be reopened once RSVN ready */
      /** ===================================================== */
      // if (type == "is_malaysian" && value == false) {
      //   this.state.passengers[index].nric = "";
      // }
      /** ===================================================== */

      if (
        type == "category" &&
        (value == "adult" || value == "single_supplement")
      ) {
        // this.state.passengers[index].showInfants = true;
        this.state.passengers[index].showInfantLink = true;
      } else {
        this.state.passengers[index].showInfants = false;
        this.state.passengers[index].showInfantLink = false;
        this.state.passengers[index].infants = [];
      }

      for (var i = 0; i < this.state.passengers.length; i++) {
        if (this.state.passengers[i]["visible"]) {
          if (
            this.state.passengers[i] &&
            this.state.passengers[i].visible === true
          ) {
            let found_price = prices.find(
              (x) =>
                x.name === this.state.passengers[i].category &&
                x.type ===
                  this.state.passengers[i].price_types.find(
                    (x) => x.name === this.state.passengers[i].category
                  ).type
            );
            if (found_price === undefined) {
              prices.push({
                name: this.state.passengers[i].category,
                type: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).type,
                quantity: 1,
                price: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).price,
                dta: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).dta,
              });
            } else {
              found_price.quantity += 1;
            }

            // infant prices
            if (this.state.passengers[i].infants.length > 0) {
              let found_infant = infant_prices.find(
                (x) =>
                  x.name === "infant" &&
                  x.type ===
                    this.state.passengers[i].price_types.find(
                      (x) => x.name === this.state.passengers[i].category
                    ).type
              );
              if (found_infant === undefined) {
                infant_prices.push({
                  name: "infant",
                  type: this.state.passengers[i].price_types.find(
                    (x) => x.name === this.state.passengers[i].category
                  ).type,
                  quantity: this.state.passengers[i].infants.length,
                  price: infant_price,
                  dta: infant_dta,
                });
              } else {
                found_infant.quantity +=
                  this.state.passengers[i].infants.length;
              }
            }
          }
        }
      }

      let total_adult_twin = 0;
      let total_child_with_bed = 0;
      let total_child_no_bed = 0;
      let total_single_supp = 0;
      let total_infant = 0;
      prices
        .filter(
          (x) => x.name.includes("adult") || x.name.includes("child_twin")
        )
        .map((price) => {
          total_adult_twin += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("child_with_bed"))
        .map((price) => {
          total_child_with_bed += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("child_no_bed"))
        .map((price) => {
          total_child_no_bed += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("single_supplement"))
        .map((price) => {
          total_single_supp += price.quantity;
        });
      infant_prices
        .filter((x) => x.name.includes("infant"))
        .map((price) => {
          total_infant += price.quantity;
        });

      this.setState(
        {
          passengers: this.state.passengers,
          total_adult_twin: total_adult_twin,
          total_child_with_bed: total_child_with_bed,
          total_child_no_bed: total_child_no_bed,
          total_single_supp: total_single_supp,
          total_infant: total_infant,
          prices: prices,
          infant_prices: infant_prices,
        },
        () => {
          this.handleQuantity(prices, infant_prices);
        }
      );
    }
  };

  /****************************** addInfants *******************************/
  addInfants(passenger_index) {
    let infant_prices = this.state.infant_prices;
    let price_type_index = 0;
    let infant_price =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
        .infant;
    let infant_dta =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
        .dta_infant;

    if (this.state.passengers[passenger_index].infants.length < 2) {
      let found_infant = infant_prices.find(
        (x) =>
          x.name === "infant" &&
          x.type ===
            this.state.passengers[passenger_index].price_types.find(
              (x) => x.name === this.state.passengers[passenger_index].category
            ).type
      );
      if (found_infant === undefined) {
        infant_prices.push({
          name: "infant",
          type: this.state.passengers[passenger_index].price_types.find(
            (x) => x.name === this.state.passengers[passenger_index].category
          ).type,
          quantity: 1,
          price: infant_price,
          dta: infant_dta,
        });
      } else {
        found_infant.quantity += 1;
      }
      this.state.passengers[passenger_index].infants = [
        ...this.state.passengers[passenger_index].infants,
        { name: "", date_of_birth: "", gender: "", nric: "" },
      ];
      this.state.passengers[passenger_index].showInfants = true;
    }

    this.setState(
      {
        passengers: this.state.passengers,
        infant_prices: infant_prices,
      },
      () => {
        this.handleQuantity(this.state.prices, infant_prices);
      }
    );
  }

  /****************************** removeInfants *******************************/
  removeInfants(passenger_index, index) {
    let infant_prices = this.state.infant_prices;
    let found_infant = infant_prices.find(
      (x) =>
        x.name === "infant" &&
        x.type ===
          this.state.passengers[passenger_index].price_types.find(
            (x) => x.name === this.state.passengers[passenger_index].category
          ).type
    );
    found_infant.quantity -= 1;

    this.state.passengers[passenger_index].infants = this.state.passengers[
      passenger_index
    ].infants.splice(0, index);
    // this.state.passengers[passenger_index].infants = this.state.passengers[passenger_index].infants.filter((s, sidx) => index !== sidx);
    this.state.passengers[passenger_index].showInfants =
      index == 0 ? false : true; // index == 0 means all infant field is removed, so hide whole section

    this.setState(
      {
        passengers: this.state.passengers,
        infant_prices: infant_prices,
      },
      () => {
        this.handleQuantity(this.state.prices, infant_prices);
      }
    );
  }

  handleInfant(passenger_index, type) {
    this.state.passengers[passenger_index].showInfantLink = true;

    this.setState(
      {
        passengers: this.state.passengers,
      },
      () => {
        this.addInfants(passenger_index);
      }
    );
  }

  onChangeDataInfant = (passenger_index, index, type, value, e = "") => {
    this.state.passengers[passenger_index].infants[index][type] = value;
    if (type == "is_malaysian" && value == false) {
      this.state.passengers[passenger_index].infants[index]["nric"] = "";
    }

    this.setState({
      passengers: this.state.passengers,
    });
  };

  // onChangeDataBooking = (i, type, value, e = '') => {
  //   if (type == "sub_sale_rep") {
  //     this.setState({
  //       sub_sale_rep: value
  //     });
  //   } else if (type == "quantity") {
  //     // let addPassenger = this.state.passengers.concat({ designation: '', name: '', date_of_birth: '', passport_number: '', category: this.state.passengers[0]['category'], remark: '' }).filter((s, sidx) => value > sidx)

  //     // if (this.props.dataList != this.state.detail) {
  //       // let addPassengers = [];
  //       let max_booking_seats = this.props.dataList && this.props.dataList.tours[this.props.tourKey].max_booking_seats;
  //       let price_type_index = 0
  //       let total_seats = this.props.dataList && this.props.dataList.tours[this.props.tourKey].prices[price_type_index].seats

  //         for (var i = 0; i < max_booking_seats; i++) {
  //           if (i < value) {
  //             this.state.passengers[i]['visible'] = true
  //           } else {
  //             this.state.passengers[i]['visible'] = false
  //           }

  //           if (i >= total_seats && price_type_index < total_seats) {
  //             price_type_index += 1
  //             total_seats += this.props.dataList && this.props.dataList.tours[this.props.tourKey].prices[price_type_index].seats
  //           }
  //           this.state.passengers[i].price_types.find(x => x.name === 'adult').price = this.props.dataList && this.props.dataList.tours[this.props.tourKey].prices[price_type_index].adult
  //           this.state.passengers[i].price_types.find(x => x.name === 'child_with_bed').price = this.props.dataList && this.props.dataList.tours[this.props.tourKey].prices[price_type_index].child_with_bed
  //           this.state.passengers[i].price_types.find(x => x.name === 'child_no_bed').price = this.props.dataList && this.props.dataList.tours[this.props.tourKey].prices[price_type_index].child_no_bed
  //           this.state.passengers[i].price_types.find(x => x.name === 'child_twin').price = this.props.dataList && this.props.dataList.tours[this.props.tourKey].prices[price_type_index].child_twin

  //           if (this.state.passengers[i].visible === true) {
  //             this.state.passengers[i].price_types.map((price_type,price_type_index) => {
  //               this.state.prices[price_type_index].name = price_type.name
  //               this.state.prices[price_type_index].quantity = this.state.passengers.filter(x => x.category === price_type.name && x.visible === true).length
  //             })
  //           }

  //         }

  //         this.setState({
  //           detail: this.props.dataList,
  //           passengers: this.state.passengers,
  //           prices: this.state.prices,
  //           quantity: value
  //         })
  //     // }
  //     this.handleQuantity(this.state.passengers)
  //   } else {
  //     //
  //   }
  // }

  onOpenModal = (modal, key, content) => {
    if (modal == "moreInfoModal") {
      this.setState({
        moreInfoModal: true,
        moreInfoModalcontent: content,
      });
    } else if (modal == "redLuggageInfoModal") {
      this.setState({
        redLuggageInfoModal: true,
        redLuggageInfoModalcontent: content,
      });
    } else if (modal == "latestInfoModal") {
      this.setState({
        latestInfoModal: true,
        latestInfoModalcontent: content,
      });
    } else if (modal == "itineraryModal") {
      this.setState({
        itineraryModalVisible: true,
        itineraryModalContent: content,
      });
    } else if (modal == "freeTravelInsuranceModal") {
      this.setState({
        freeTravelInsuranceModal: true,
      });
    }
  };

  onCloseModal = (modal) => {
    this.setState({
      bookingErrorModal: false,
      bookingConfirmModal: false,
      moreInfoModal: false,
      redLuggageInfoModal: false,
      latestInfoModal: false,
      itineraryModalVisible: false,
      freeTravelInsuranceModal: false,
    });
  };

  // onClickMore = () => {
  //   this.setState({
  //     moreInfoModal: true,
  //   });
  // };

  onConfirmBooking = (event) => {
    event.preventDefault();

    let prices = [];
    let sub_prices = [];
    let infant_prices = [];
    let infant_price =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].prices[0].infant;
    let infant_dta =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].prices[0].dta_infant;

    for (var i = 0; i < this.state.passengers.length; i++) {
      if (this.state.passengers[i]["visible"]) {
        if (
          this.state.passengers[i] &&
          this.state.passengers[i].visible === true
        ) {
          let found_price = prices.find(
            (x) =>
              x.type ===
                this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).type && x.name === this.state.passengers[i].category
          );
          let found_subprice = sub_prices.find(
            (x) =>
              x.type ===
              this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).type
          );

          if (found_price === undefined) {
            prices.push({
              name: this.state.passengers[i].category,
              type: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).type,
              quantity: 1,
              price: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).price,
              dta: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).dta,
            });
          } else {
            found_price.quantity += 1;
          }

          if (found_subprice === undefined) {
            sub_prices.push({
              name: this.state.passengers[i].category,
              type: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).type,
              quantity: 1,
              price: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).price,
              dta: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).dta,
            });
          } else {
            found_subprice.quantity += 1;
          }

          // infant prices
          if (this.state.passengers[i].infants.length > 0) {
            let found_infant = infant_prices.find(
              (x) =>
                x.name === "infant" &&
                x.type ===
                  this.state.passengers[i].price_types.find(
                    (x) => x.name === this.state.passengers[i].category
                  ).type
            );
            if (found_infant === undefined) {
              infant_prices.push({
                name: "infant",
                type: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).type,
                quantity: this.state.passengers[i].infants.length,
                price: infant_price,
                dta: infant_dta,
              });
            } else {
              found_infant.quantity += this.state.passengers[i].infants.length;
            }
          }
        }
      }
    }

    this.setState(
      {
        passengers: this.state.passengers,
        confirm_button_disabled: false,
      },
      () => {
        this.handleQuantity(prices, infant_prices);
      }
    );

    // setTimeout(() => {
    const {
      seats,
      quantity,
      sub_sale_rep,
      tour_id,
      passengers,
      payment_type,
      payment_method,
      bank_type,
      validationErrors,
      agree,
      fare_type,
      suka_insurance,
      fare_type_selected,
      set_amount,
      redirect_url,
    } = this.state;
    let allow_customer_pay =
      this.props.dataList.tours &&
      this.props.dataList.tours[this.props.tourKey].allow_customer_pay;

    let outputJson = {
      booking_seats: sub_prices
        .map((x) => {
          return x.quantity + " " + x.type + "(" + x.price + ")";
        })
        .join(", "),
      sub_sale_rep: sub_sale_rep,
      tour_id:
        this.props.dataList.tours &&
        this.props.dataList.tours[this.props.tourKey].id,
      bookings: passengers.filter((x) => x.visible === true),
      payment: {
        gateway: payment_type,
        method: payment_method,
        bank_type: bank_type,
        redirect_url: redirect_url,
      },
      fare_type: fare_type,
      fare_type_selected: fare_type_selected,
      set_amount: set_amount,
      suka_insurance: suka_insurance,
      agree: agree,
    };
    console.log("This is the outputJson", outputJson);

    let passengerValidate = passengers.filter((x) => x.visible === true);
    console.log("passengerValidate", passengerValidate);
    console.log("validationErrors", validationErrors);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (
        payment_type === "" ||
        (payment_type === "credit_transaction" || payment_type === "pay_later"
          ? ""
          : payment_method === null) ||
        agree === "" ||
        !err === false ||
        (payment_type === "credit_transaction" ||
        payment_type === "pay_later" ||
        homepage_new.suka === "true" ||
        (!payment_type.includes("fpx") && !payment_type.includes("public_bank"))
          ? ""
          : fare_type_selected === "") ||
        (payment_type == "fpx_b2b" &&
          (this.props.final_price < 2 || this.props.final_price > 1000000)) ||
        (payment_type == "fpx_b2c" &&
          (this.props.final_price < 1 || this.props.final_price > 30000))
      ) {
        if (!err) {
          console.log("Received values of form: ", values);
        }
        if (payment_type === "") {
          this.openNotificationWithIcon("warning", "payment_method_error");
        }
        if (payment_method === null) {
          validationErrors.payment_method = "*Please select a payment method";
          this.setState({ validationErrors: validationErrors });
        } else {
          validationErrors.payment_method = null;
          this.setState({ validationErrors: validationErrors });
        }
        if (agree === "") {
          validationErrors.agree =
            "*Please agreed the Terms of Use and Privacy Statement";
          this.setState({ validationErrors: validationErrors });
        } else {
          validationErrors.agree = "";
          this.setState({ validationErrors: validationErrors });
        }
        if (payment_type === "fpx_b2b" && this.props.final_price < 2) {
          this.openNotificationWithIcon("error", "fpxb2b_min_error");
        }
        if (payment_type === "fpx_b2b" && this.props.final_price > 1000000) {
          this.openNotificationWithIcon("error", "fpxb2b_max_error");
        }
        if (payment_type === "fpx_b2c" && this.props.final_price < 1) {
          this.openNotificationWithIcon("error", "fpxb2c_min_error");
        }
        if (payment_type === "fpx_b2c" && this.props.final_price > 30000) {
          this.openNotificationWithIcon("error", "fpxb2c_max_error");
        }
        if (homepage_new.suka === "true" && suka_insurance === null) {
          validationErrors.suka_insurance =
            "*Please select the travel insurance";
          this.setState({ validationErrors: validationErrors });
        } else {
          validationErrors.suka_insurance = "";
          this.setState({ validationErrors: validationErrors });
        }
        if (homepage_new.suka === "false" && fare_type_selected === "") {
          validationErrors.fare_type_selected =
            "*Please select the payment terms";
          this.setState({ validationErrors: validationErrors });
        } else {
          validationErrors.fare_type_selected = "";
          this.setState({ validationErrors: validationErrors });
        }
      } else {
        this.setState({
          validationErrors: [],
          bookingConfirmModal: true,
        });
      }
    });
    console.log("Check validationerros", this.state.validationErrors);
    // },100);
  };

  // handleCancel = () => {
  //   console.log('Clicked cancel button');
  //   this.setState({
  //     bookingConfirmModal: false,
  //     moreInfoModal: false
  //   });
  // };

  /****************************** onChangeConfirmationCheckbox *******************************/
  onChangeConfirmationCheckbox(type, e = "") {
    this.state.confirmCheck[type] = e.target.checked;
    this.setState({
      confirmCheck: this.state.confirmCheck,
    });
    console.log("Confirm Check ---> ", this.state.confirmCheck);
  }
  /****************************** onChangePaymentCheckBox *******************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked,
    });
  }

  /****************************** handlePaymentChange *******************************/
  handlePaymentChange(event) {
    console.log(event.target.value);
    let fare_type_selected = "";
    if (!this.props.dataList.tours[this.props.tourKey].allow_customer_pay) {
      if (
        this.state.fare_type_selected == "" &&
        this.state.fare_type == "deposit"
      ) {
        fare_type_selected = "agent_deposit";
      } else {
        fare_type_selected = "agent_full_fare";
      }
    }

    if (event.target.value === "public_bank") {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null,
        fare_type_selected: fare_type_selected,
      });
    } else if (event.target.value === "public_bank_2") {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null,
        fare_type_selected: fare_type_selected,
      });
    } else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null,
        fare_type_selected: fare_type_selected,
      });
    } else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null,
        fare_type_selected: fare_type_selected,
      });
    } else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null,
        fare_type_selected: fare_type_selected,
      });
    } /** if (event.target.value === 'credit_transaction') **/ else {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null,
        fare_type_selected: fare_type_selected,
      });
    }
    this.handlePaymentType(event.target.value, fare_type_selected);

    this.setState({
      payment_type: event.target.value,
    });
  }

  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = "";
    let redirect_url = "";

    if (this.state.payment_type === "dobw") {
      var found_bank = this.state.banklist_select.find(
        (val) => val[1] === payment_method
      );
      redirect_url = found_bank[2];
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url,
    });
    // console.log("card method",value)
  }

  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: "Warning!",
          description: "Please select payment method before you continue.",
        });
      }
    }
    if (type === "error") {
      if (error_name === "credits_error") {
        notification[type]({
          message: "Error!",
          description: "Insufficient credits! please purchase more credits.",
        });
      }
      if (error_name === "fpxb2b_min_error") {
        notification[type]({
          message: "Error!",
          description:
            "Transaction Amount is Lower than the Minimum Limit RM2.00 for B2B1.",
        });
      }

      if (error_name === "fpxb2b_max_error") {
        notification[type]({
          message: "Error!",
          description:
            "Maximum Transaction Limit Exceeded RM1,000 000 for B2B1.",
        });
      }
      if (error_name === "fpxb2c_min_error") {
        notification[type]({
          message: "Error!",
          description:
            "Transaction Amount is Lower than the Minimum Limit RM1.00 for B2C.",
        });
      }

      if (error_name === "fpxb2c_max_error") {
        notification[type]({
          message: "Error!",
          description: "Maximum Transaction Limit Exceeded RM30,000 for B2C.",
        });
      }
    }
  };

  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  getAdultName() {
    if (this.state.is_cruise) {
      return "Twin (Adult/Child)";
    } else if (homepage_new.suka === "true") {
      return "Adult Twin/Triple";
    } else {
      return "Adult";
    }
  }

  getSingleName() {
    if (this.state.is_cruise) {
      return "Single (Adult)";
    } else {
      return "Single";
    }
  }

  banklistFetch() {
    let url = "./api/v1/payments/payment_method";
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        });
        that.props.getCredits(response.data.credits);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  applyGuaranteedColor(indicator) {
    if (indicator === "G") {
      return "green";
    }

    return "cyan";
  }

  componentDidMount() {
    if (is_logged_in) {
      this.banklistFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataList.hasOwnProperty("tours")) {
      let passengers = [];
      let prices = [];
      let is_cruise =
        this.props.dataList && this.props.dataList.tour_type == "cruise"
          ? true
          : false;
      let price_types = [];
      let infant_prices = [];

      if (this.props.dataList.tours[this.props.tourKey].prices.length == 0) {
        return;
      }

      if (this.state.passengers.length == 0) {
        for (var i = 0; i < this.props.dataList.max_booking_seats; i++) {
          if (!is_cruise) {
            passengers.push({
              designation: "Mr",
              name: "",
              date_of_birth: "",
              mobile: "",
              passport_number: "",
              is_malaysian: true,
              category: "adult",
              nric: "",
              remark: "",
              price_types: [
                { name: "adult", visiable: true },
                { name: "single_supplement", visiable: true },
                { name: "child_twin", visiable: false },
                { name: "child_with_bed", visiable: false },
                { name: "child_no_bed", visiable: false },
              ],
              visible: false,
              showInfantLink: true,
              showInfants: false,
              infants: [],
            });
          } else {
            passengers.push({
              designation: "Mr",
              name: "",
              date_of_birth: "",
              mobile: "",
              passport_number: "",
              is_malaysian: true,
              category: "adult",
              nric: "",
              remark: "",
              price_types: [
                { name: "adult", visiable: true },
                { name: "single_supplement", visiable: true },
                { name: "child_twin", visiable: false },
              ],
              visible: false,
              showInfantLink: true,
              showInfants: false,
              infants: [],
            });
          }
        }
      } else {
        passengers = this.state.passengers;
      }

      let max_booking_seats =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].max_booking_seats;
      let price_type_index = 0;
      let total_seats =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .seats;
      let quantity =
        this.state.quantity > max_booking_seats
          ? max_booking_seats
          : this.state.quantity;
      let infant_price =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .infant;
      let infant_dta =
        this.props.dataList &&
        this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
          .dta_infant;

      for (var i = 0; i < passengers.length; i++) {
        if (i < quantity) {
          passengers[i]["visible"] = true;
        } else {
          passengers[i]["visible"] = false;
        }

        if (
          i >= total_seats &&
          price_type_index <
            this.props.dataList.tours[this.props.tourKey].prices.length - 1
        ) {
          price_type_index += 1;
          total_seats +=
            this.props.dataList &&
            this.props.dataList.tours[this.props.tourKey].prices[
              price_type_index
            ].seats;
        }

        // Validation of category dropdown select
        if (passengers[i] && passengers[i].visible === true) {
          for (var k = 0; k < passengers[i].price_types.length; k++) {
            if (
              passengers[i].category == "adult" ||
              passengers[i].category == "single_supplement" ||
              passengers[i].category == "child_twin"
            ) {
              total_adult_twin = this.state.total_adult_twin - 1;
            } else {
              total_adult_twin = this.state.total_adult_twin;
            }

            let left_child_no_bed = Math.floor(total_adult_twin / 2);
            let left_total_child_with_bed = 0;

            if (total_adult_twin % 2 == 1) {
              left_total_child_with_bed = Math.floor(
                (total_adult_twin - 3) / 2
              );
            } else {
              left_total_child_with_bed = Math.floor(total_adult_twin / 2);
            }

            // console.log(total_adult_twin, "total_adult_twin")
            // console.log(left_total_child_with_bed, "left_total_child_with_bed")
            // console.log(left_child_no_bed, "left_child_no_bed")

            if (passengers[i].price_types[k].name == "child_no_bed") {
              passengers[i].price_types[k].visible =
                passengers[i].category == "child_no_bed" ||
                (left_total_child_with_bed - this.state.total_child_with_bed >=
                  0 &&
                  left_child_no_bed - this.state.total_child_no_bed > 0);
            } else if (passengers[i].price_types[k].name == "child_with_bed") {
              passengers[i].price_types[k].visible =
                passengers[i].category == "child_with_bed" ||
                (left_total_child_with_bed - this.state.total_child_with_bed >
                  0 &&
                  left_child_no_bed - this.state.total_child_no_bed >= 0);
            } else {
              passengers[i].price_types[k].visible =
                passengers[i].category != "child_no_bed" ||
                this.state.total_child_with_bed == 0 ||
                left_total_child_with_bed - this.state.total_child_with_bed > 0;
            }
            // console.log(passengers[i].price_types[k].visible, passengers[i].price_types[k].name)
          }

          let child_options = passengers[i].price_types.filter((x) =>
            x.name.includes("child")
          );
          for (var j = 0; j < child_options.length; j++) {
            // if date_of_birth is more than 12 years old, then disable the child options
            // console.log(passengers[i].date_of_birth, "passengers[i].date_of_birth")
            child_options[j].visible &=
              this.getAge(passengers[i].date_of_birth) <= 12;
          }

          if (
            passengers[i]["category"] == "adult" ||
            passengers[i]["category"] == "single_supplement"
          ) {
            passengers[i].showInfantLink = true;
          }
        }

        passengers[i].price_types.find((x) => x.name === "adult").price =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .adult;
        passengers[i].price_types.find(
          (x) => x.name === "single_supplement"
        ).price =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .single_supplement;
        passengers[i].price_types.find((x) => x.name === "child_twin").price =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .child_twin;

        passengers[i].price_types.find((x) => x.name === "adult").type =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .type;
        passengers[i].price_types.find(
          (x) => x.name === "single_supplement"
        ).type =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .type;
        passengers[i].price_types.find((x) => x.name === "child_twin").type =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .type;

        passengers[i].price_types.find((x) => x.name === "adult").dta =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .dta_adult;
        passengers[i].price_types.find(
          (x) => x.name === "single_supplement"
        ).dta =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .dta_adult;
        passengers[i].price_types.find((x) => x.name === "child_twin").dta =
          this.props.dataList &&
          this.props.dataList.tours[this.props.tourKey].prices[price_type_index]
            .dta_child_twin;

        if (!is_cruise) {
          passengers[i].price_types.find(
            (x) => x.name === "child_with_bed"
          ).price =
            this.props.dataList &&
            this.props.dataList.tours[this.props.tourKey].prices[
              price_type_index
            ].child_with_bed;
          passengers[i].price_types.find(
            (x) => x.name === "child_no_bed"
          ).price =
            this.props.dataList &&
            this.props.dataList.tours[this.props.tourKey].prices[
              price_type_index
            ].child_no_bed;

          passengers[i].price_types.find(
            (x) => x.name === "child_with_bed"
          ).type =
            this.props.dataList &&
            this.props.dataList.tours[this.props.tourKey].prices[
              price_type_index
            ].type;
          passengers[i].price_types.find(
            (x) => x.name === "child_no_bed"
          ).type =
            this.props.dataList &&
            this.props.dataList.tours[this.props.tourKey].prices[
              price_type_index
            ].type;

          passengers[i].price_types.find(
            (x) => x.name === "child_with_bed"
          ).dta =
            this.props.dataList &&
            this.props.dataList.tours[this.props.tourKey].prices[
              price_type_index
            ].dta_child_with_bed;
          passengers[i].price_types.find((x) => x.name === "child_no_bed").dta =
            this.props.dataList &&
            this.props.dataList.tours[this.props.tourKey].prices[
              price_type_index
            ].dta_child_no_bed;
        }
      }

      for (var i = 0; i < quantity; i++) {
        if (passengers[i] && passengers[i].visible === true) {
          let found_price = prices.find(
            (x) =>
              x.name === passengers[i].category &&
              x.type ===
                passengers[i].price_types.find(
                  (x) => x.name === passengers[i].category
                ).type
          );
          if (found_price === undefined) {
            prices.push({
              name: passengers[i].category,
              type: passengers[i].price_types.find(
                (x) => x.name === passengers[i].category
              ).type,
              quantity: 1,
              price: passengers[i].price_types.find(
                (x) => x.name === passengers[i].category
              ).price,
              dta: passengers[i].price_types.find(
                (x) => x.name === passengers[i].category
              ).dta,
            });
          } else {
            found_price.quantity += 1;
          }

          // infant prices
          if (passengers[i].infants.length > 0) {
            let found_infant = infant_prices.find(
              (x) =>
                x.name === "infant" &&
                x.type ===
                  passengers[i].price_types.find(
                    (x) => x.name === passengers[i].category
                  ).type
            );
            if (found_infant === undefined) {
              infant_prices.push({
                name: "infant",
                type: passengers[i].price_types.find(
                  (x) => x.name === passengers[i].category
                ).type,
                quantity: passengers[i].infants.length,
                price: infant_price,
                dta: infant_dta,
              });
            } else {
              found_infant.quantity += passengers[i].infants.length;
            }
          }
        }
      }

      let total_adult_twin = 0;
      let total_child_with_bed = 0;
      let total_child_no_bed = 0;
      let total_single_supp = 0;
      let total_infant = 0;
      // let payment_method
      // let fare_type = this.props.dataList.tours && this.props.dataList.tours[this.props.tourKey].guaranteed_departure === false ? "deposit" : "full fare"
      let fare_type =
        this.props.dataList.tours &&
        this.props.dataList.tours[this.props.tourKey].fare_type;

      prices
        .filter(
          (x) => x.name.includes("adult") || x.name.includes("child_twin")
        )
        .map((price) => {
          total_adult_twin += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("child_with_bed"))
        .map((price) => {
          total_child_with_bed += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("child_no_bed"))
        .map((price) => {
          total_child_no_bed += price.quantity;
        });
      prices
        .filter((x) => x.name.includes("single_supplement"))
        .map((price) => {
          total_single_supp += price.quantity;
        });
      infant_prices
        .filter((x) => x.name.includes("infant"))
        .map((price) => {
          total_infant += price.quantity;
        });

      if (
        prevProps.tourKey !== this.props.tourKey ||
        prevProps.dataList !== this.state.detail
      ) {
        this.setState({
          detail: this.props.dataList,
          passengers: passengers,
          prices: prices,
          infant_prices: infant_prices,
          quantity: quantity,
          total_adult_twin: total_adult_twin,
          total_child_with_bed: total_child_with_bed,
          total_child_no_bed: total_child_no_bed,
          fare_type: fare_type,
          fare_type_selected: "",
          set_amount: 0,
          is_cruise: is_cruise,
          total_single_supp: total_single_supp,
          total_infant: total_infant,
        });
        this.handleQuantity(prices, infant_prices);
      }
    }
  }

  submitForm = (event) => {
    event.preventDefault();
    this.banklistFetch();

    this.setState({
      confirmLoading: true,
      confirm_button_disabled: true,
    });

    let prices = [];
    let sub_prices = [];
    let infant_prices = [];
    let infant_price =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].prices[0].infant;
    let infant_dta =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].prices[0].dta_infant;

    for (var i = 0; i < this.state.passengers.length; i++) {
      if (this.state.passengers[i]["visible"]) {
        if (
          this.state.passengers[i] &&
          this.state.passengers[i].visible === true
        ) {
          let found_price = prices.find(
            (x) =>
              x.type ===
                this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).type && x.name === this.state.passengers[i].category
          );
          let found_subprice = sub_prices.find(
            (x) =>
              x.type ===
              this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).type
          );

          if (found_price === undefined) {
            prices.push({
              name: this.state.passengers[i].category,
              type: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).type,
              quantity: 1,
              price: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).price,
              dta: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).dta,
            });
          } else {
            found_price.quantity += 1;
          }

          if (found_subprice === undefined) {
            sub_prices.push({
              name: this.state.passengers[i].category,
              type: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).type,
              quantity: 1,
              price: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).price,
              dta: this.state.passengers[i].price_types.find(
                (x) => x.name === this.state.passengers[i].category
              ).dta,
            });
          } else {
            found_subprice.quantity += 1;
          }

          // infant prices
          if (this.state.passengers[i].infants.length > 0) {
            let found_infant = infant_prices.find(
              (x) =>
                x.name === "infant" &&
                x.type ===
                  this.state.passengers[i].price_types.find(
                    (x) => x.name === this.state.passengers[i].category
                  ).type
            );
            if (found_infant === undefined) {
              infant_prices.push({
                name: "infant",
                type: this.state.passengers[i].price_types.find(
                  (x) => x.name === this.state.passengers[i].category
                ).type,
                quantity: this.state.passengers[i].infants.length,
                price: infant_price,
                dta: infant_dta,
              });
            } else {
              found_infant.quantity += this.state.passengers[i].infants.length;
            }
          }
        }
      }
    }
    this.setState(
      {
        passengers: this.state.passengers,
      },
      () => {
        this.handleQuantity(prices, infant_prices);
      }
    );

    setTimeout(() => {
      const {
        seats,
        quantity,
        sub_sale_rep,
        tour_id,
        passengers,
        payment_type,
        payment_method,
        bank_type,
        validationErrors,
        agree,
        fare_type,
        suka_insurance,
        fare_type_selected,
        set_amount,
        redirect_url,
      } = this.state;

      let outputJson = {
        booking_seats: sub_prices
          .map((x) => {
            return x.quantity + " " + x.type + "(" + x.price + ")";
          })
          .join(", "),
        sub_sale_rep: sub_sale_rep,
        tour_id:
          this.props.dataList.tours &&
          this.props.dataList.tours[this.props.tourKey].id,
        bookings: passengers.filter((x) => x.visible === true),
        payment: {
          gateway: payment_type,
          method: payment_method,
          bank_type: bank_type,
          redirect_url: redirect_url,
        },
        agree: agree,
        fare_type_selected: fare_type_selected,
        set_amount: set_amount,
        suka_insurance: suka_insurance,
        fare_type: fare_type,
      };
      console.log("This is the outputJson", outputJson);

      // let passengerValidate = passengers.filter(x => x.visible === true)
      // console.log("passengerValidate", passengerValidate);
      // console.log("validationErrors", validationErrors);
      this.setState({
        check_out_disabled: true,
      });

      let url = "./api/v1/series/" + this.props.dataList.id + "/bookings";
      let that = this;

      API.post(url, outputJson)
        .then(function (response) {
          console.log(response, "------------res");
          console.log(response.data.redirect_to, "------------redirect");
          if (response.data.redirect_to) {
            window.location = response.data.redirect_to;
          } else if (response.data.credits_error) {
            that.openNotificationWithIcon("error", "credits_error");
            that.setState({
              payment_type: "",
              bank_type: null,
              redirect_url: null,
              bookingConfirmModal: false,
              confirm_button_disabled: false,
            });
          } else {
            that.setState({
              check_out_disabled: false,
              bookingErrorModal: true,
              bookingErrorMessage: response.data.error,
              confirm_button_disabled: false,
            });
          }
          that.setState({
            loading: false,
            confirmLoading: false,
          });
        })
        .catch(function (error) {
          console.log(error);
          that.setState({
            visible: true,
          });
        })
        .then(function () {
          // always executed
        });
    }, 100);
  };

  render() {
    const {
      bookingErrorModal,
      bookingErrorMessage,
      bookingConfirmModal,
      confirmLoading,
      confirmCheck,
      designations,
      quantity,
      method_visible,
      passengers,
      banklist_select,
      offline_banklist,
      payment_type,
      payment_method,
      bank_type,
      /*credits,*/ validationErrors,
      showInfantField,
      showInfantLink,
      prices,
      page_width,
      numPages,
    } = this.state;
    const { bookable, discount_available, payable, is_guest_customer } =
      this.props;
    const { getFieldDecorator } = this.props.form;
    const dataList = this.props.dataList;
    const service_fee = this.props.service_fee;
    const max_booking_seats =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].max_booking_seats;
    const extra_deposit =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].extra_deposit;
    const allow_customer_pay =
      this.props.dataList &&
      this.props.dataList.tours[this.props.tourKey].allow_customer_pay;

    const documentType =
      this.props.dataList.category == "GD Domestic" ? "IC" : "Passport";

    /********************************* formItemLayout ******************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 } },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 } },
    };

    if (payment_type == "public_bank" || payment_type == "public_bank_2") {
      var final_deposit = this.props.deposit + service_fee;
    } else {
      var final_deposit = this.props.deposit;
    }
    var booking_release_info = !this.props.dataList.auto_cancel ? (
      <p className="consider_sold">Once Booked Considered Sold</p>
    ) : (
      <p>Booking will be auto release if deposit payment not received.</p>
    );

    // console.log("CHECK DATALIST ---> ", dataList)
    // console.log("check passengers ---> ", passengers)
    // console.log("check total adult twin ----> ", this.state.total_adult_twin)
    // console.log("check total child with bed ----> ", this.state.total_child_with_bed)
    // console.log("check total child no bed ----> ", this.state.total_child_no_bed)
    // const infantData = infant.slice(0,showInfants === true ? 2 : infant.length );

    return (
      <div className="LeftPanel">
        <div className="hotel_details">
          {/**************************** header title ****************************/}
          <div className="header_title">
            <h1>{dataList && dataList.tours[this.props.tourKey].caption}</h1>
            <p>
              <Tag color="orange">
                Itinerary : <strong>{dataList.code}</strong>
              </Tag>
              <Tag color="orange">
                Category : <strong>{dataList.category}</strong>
              </Tag>
            </p>
          </div>

          {/************************************************ Form ************************************************/}
          <Form
            {...formItemLayout}
            onSubmit={this.onConfirmBooking}
            className="form_sty_select"
          >
            {/**************************** tab select ****************************/}
            <Tabs
              activeKey={`${this.props.tourKey}`}
              onChange={this.handleCallback}
            >
              {dataList &&
                dataList.tours.map((tour, i) => {
                  const price_columns = [];
                  const price_datas = [];

                  tour.prices.map((price, price_index) => {
                    let price_type_text = price.type;
                    let dta_price_type_text = `dta_${price_type_text}`;
                    let price_infant_text;
                    let price_infant_dta_text;

                    price_columns.push({
                      key: price_index,
                      dataIndex: price_type_text,
                      title: (
                        <span>
                          {price.display_price}
                          <br />
                          <span>
                            {Humanize(price_type_text.replace(/[0-9]/g, ""))}
                          </span>
                        </span>
                      ),
                      rowSpan: 1,
                      width: 200,
                      className: "priceTypeColumn",
                    });

                    var found_adult = price_datas.find(
                      (x) => x.name === this.getAdultName()
                    );
                    var found_single_supp = price_datas.find(
                      (x) => x.name === this.getSingleName()
                    );
                    var found_child_twin = price_datas.find(
                      (x) => x.name === "Child Twin"
                    );

                    if (found_adult == undefined) {
                      price_datas.push({
                        key: price_index,
                        name: this.getAdultName(),
                        [price_type_text]:
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.adult),
                        [dta_price_type_text]:
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.dta_adult),
                      });
                      price_index++;
                    } else {
                      found_adult[price_type_text] =
                        dataList.price_currency +
                        " " +
                        this.priceFormat(price.adult);
                      found_adult[dta_price_type_text] =
                        dataList.price_currency +
                        " " +
                        this.priceFormat(price.dta_adult);
                    }
                    if (found_single_supp == undefined) {
                      price_datas.push({
                        key: price_index,
                        name: this.getSingleName(),
                        [price_type_text]:
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.single_supplement),
                        [dta_price_type_text]:
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.dta_single_supplement),
                      });
                      price_index++;
                    } else {
                      found_single_supp[price_type_text] =
                        dataList.price_currency +
                        " " +
                        this.priceFormat(price.single_supplement);
                      found_single_supp[dta_price_type_text] =
                        dataList.price_currency +
                        " " +
                        this.priceFormat(price.dta_single_supplement);
                    }
                    if (found_child_twin == undefined) {
                      price_datas.push({
                        key: price_index,
                        name: "Child Twin",
                        [price_type_text]:
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.child_twin),
                        [dta_price_type_text]:
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.dta_child_twin),
                      });
                      price_index++;
                    } else {
                      found_child_twin[price_type_text] =
                        dataList.price_currency +
                        " " +
                        this.priceFormat(price.child_twin);
                      found_child_twin[dta_price_type_text] =
                        dataList.price_currency +
                        " " +
                        this.priceFormat(price.dta_child_twin);
                    }

                    if (!this.state.is_cruise) {
                      var found_child_no_bed = price_datas.find(
                        (x) => x.name === "Child No Bed"
                      );
                      var found_child_with_bed = price_datas.find(
                        (x) => x.name === "Child With Bed"
                      );
                      var found_infant = price_datas.find(
                        (x) => x.name === "Infant"
                      );

                      if (found_child_no_bed == undefined) {
                        price_datas.push({
                          key: price_index,
                          name: "Child No Bed",
                          [price_type_text]:
                            dataList.price_currency +
                            " " +
                            this.priceFormat(price.child_no_bed),
                          [dta_price_type_text]:
                            dataList.price_currency +
                            " " +
                            this.priceFormat(price.dta_child_no_bed),
                        });
                        price_index++;
                      } else {
                        found_child_no_bed[price_type_text] =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.child_no_bed);
                        found_child_no_bed[dta_price_type_text] =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.dta_child_no_bed);
                      }
                      if (found_child_with_bed == undefined) {
                        price_datas.push({
                          key: price_index,
                          name: "Child With Bed",
                          [price_type_text]:
                            dataList.price_currency +
                            " " +
                            this.priceFormat(price.child_with_bed),
                          [dta_price_type_text]:
                            dataList.price_currency +
                            " " +
                            this.priceFormat(price.dta_child_with_bed),
                        });
                        price_index++;
                      } else {
                        found_child_with_bed[price_type_text] =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.child_with_bed);
                        found_child_with_bed[dta_price_type_text] =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.dta_child_with_bed);
                      }
                    }
                    if (found_infant == undefined) {
                      if (price.infant === 0) {
                        price_infant_text = "TBC";
                        price_infant_dta_text = "TBC";
                      } else {
                        price_infant_text =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.infant);
                        price_infant_dta_text =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.dta_infant);
                      }
                      price_datas.push({
                        key: price_index,
                        name: "Infant",
                        [price_type_text]: price_infant_text,
                        [dta_price_type_text]: price_infant_dta_text,
                      });
                      price_index++;
                    } else {
                      if (price.infant === 0) {
                        price_infant_text = "TBC";
                        price_infant_dta_text = "TBC";
                      } else {
                        price_infant_text =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.infant);
                        price_infant_dta_text =
                          dataList.price_currency +
                          " " +
                          this.priceFormat(price.dta_infant);
                      }
                      found_infant[price_type_text] = price_infant_text;
                      found_infant[dta_price_type_text] = price_infant_dta_text;
                    }
                  });

                  {
                    /*console.log("price_datas =====> ", price_datas)
                console.log("price_columns =====> ", price_columns)
                console.log("check_original_prices =====> ", tour.prices)*/
                  }

                  return (
                    <TabPane
                      tab={[
                        <div className="tab_select_style" key={i}>
                          <p className="date_tab">
                            {moment(tour.departure_date, "DD-MM-YYYY").format(
                              dateFormat
                            )}{" "}
                            {tour.guaranteed_indicator === "" ? null : (
                              <Tag
                                className="guaranteed_tag"
                                color={this.applyGuaranteedColor(
                                  tour.guaranteed_indicator
                                )}
                              >
                                {tour.guaranteed_indicator}
                              </Tag>
                            )}
                            {tour.departure_location === "" ||
                            tour.departure_location === null ? null : (
                              <Tag className="location_tag" color="blue">
                                {tour.departure_location}
                              </Tag>
                            )}
                          </p>
                          <p className="from_sty">From</p>
                          <p className="tab_price">
                            {dataList.price_currency}{" "}
                            {this.priceFormat(tour.price)}
                          </p>
                        </div>,
                      ]}
                      key={tour.code}
                    >
                      {/**************************** Things to know ****************************/}
                      {homepage_new.suka === "false" && (
                        <Card className="things">
                          <div className="quick-info">
                            <h3 className="title">Things to know</h3>
                            <Row className="things_to_know" gutter={2}>
                              {tour.departure_location === "" ||
                              tour.departure_location === null ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="door-open"
                                      />
                                    </span>
                                    <span className="content_text">
                                      EX {tour.departure_location}
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {tour.insurance === "" ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="user-shield"
                                      />
                                    </span>
                                    <span
                                      className="content_text"
                                      onClick={() =>
                                        this.onOpenModal(
                                          "freeTravelInsuranceModal",
                                          0,
                                          tour.insurance
                                        )
                                      }
                                    >
                                      <a>{tour.insurance}</a>
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {this.state.fare_type === "deposit" ? (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon fixedWidth icon="male" />
                                    </span>
                                    <span className="content_text">
                                      Deposit per pax{" "}
                                      <strong>
                                        {dataList.price_currency}{" "}
                                        {this.priceFormat(
                                          is_guest_customer
                                            ? tour.customer_deposit
                                            : this.state.fare_type_selected ===
                                              "customer_deposit"
                                            ? tour.deposit + tour.extra_deposit
                                            : tour.deposit
                                        )}
                                      </strong>
                                    </span>
                                  </div>
                                </Col>
                              ) : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="hand-holding-usd"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Full Payment Required
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.auto_cancel ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="shopping-bag"
                                      />
                                    </span>
                                    <span className="content_text red">
                                      Once Booked Considered Sold
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {/*{
                            tour.final_payment_date === "" ? (
                              null
                            ) : (
                              <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon"><FontAwesomeIcon fixedWidth icon="clock" /></span>
                                    <span className="content_text">
                                      <span className="cutoff">Cut Off :</span>
                                      {moment(tour.final_payment_date,"DD-MM-YYYY").format(dateFormat)}
                                    </span>
                                  </div>
                              </Col>
                              )
                          }*/}
                              {tour.guaranteed_departure === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <Tag
                                        className="guaranteed_tag"
                                        color="green"
                                      >
                                        G
                                      </Tag>
                                    </span>
                                    <span className="content_text"></span>
                                    Guaranteed Departure
                                  </div>
                                </Col>
                              )}
                              {dataList.file_url === "" &&
                              dataList.cn_file_url === "" ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="clipboard-list"
                                      />
                                    </span>
                                    Itinerary Download
                                    {dataList.file_url !== "" ? (
                                      <span className="content_text">
                                        <a
                                          href={dataList.file_url}
                                          target="_blank"
                                        >
                                          {" "}
                                          EN
                                        </a>
                                        &nbsp;
                                        <CopyToClipboardTooltip
                                          text={dataList.file_copy_url}
                                        >
                                          <FontAwesomeIcon
                                            size="sm"
                                            icon={faCopy}
                                            className="itinerary_clipboard_icon"
                                          />
                                        </CopyToClipboardTooltip>
                                        &nbsp;
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {dataList.file_url !== "" &&
                                    dataList.cn_file_url !== "" ? (
                                      <span className="content_text">|</span>
                                    ) : (
                                      ""
                                    )}
                                    {dataList.cn_file_url !== "" ? (
                                      <span className="content_text">
                                        <a
                                          href={dataList.cn_file_url}
                                          target="_blank"
                                        >
                                          CN
                                        </a>
                                        &nbsp;
                                        <CopyToClipboardTooltip
                                          text={dataList.file_cn_copy_url}
                                        >
                                          <FontAwesomeIcon
                                            size="sm"
                                            icon={faCopy}
                                            className="itinerary_clipboard_icon"
                                          />
                                        </CopyToClipboardTooltip>
                                        &nbsp;
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {/*<span onClick={() => this.onOpenModal('itineraryModal', 0, dataList.file_url)} className="itinerary_text"> EN</span> | <span onClick={() => this.onOpenModal('itineraryModal', 0, dataList.cn_file_url)} className="itinerary_text"> CN </span>*/}
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["airport_taxes"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="receipt"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Airport Taxes
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["group_departure"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="users"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Group Departure
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["tour_leader"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="user-check"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Tour Leader
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["luggage"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="shopping-bag"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Check-in baggage
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {tour.red_luggage_protection === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="suitcase"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Red Luggage Protection
                                    </span>
                                    <span
                                      onClick={() =>
                                        this.onOpenModal(
                                          "redLuggageInfoModal",
                                          0,
                                          red_luggage
                                        )
                                      }
                                      className="content_text"
                                    >
                                      <a>
                                        <FontAwesomeIcon
                                          fixedWidth
                                          icon="info-circle"
                                        />
                                      </a>
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["wifi"] === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon fixedWidth icon="wifi" />
                                    </span>
                                    <span className="content_text">
                                      Share Wifi
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["meal_onboard"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="utensils"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Meal Onboard
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["hotel"] === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon fixedWidth icon="bed" />
                                    </span>
                                    <span className="content_text">Hotel</span>
                                  </div>
                                </Col>
                              )}
                              {tour.pay_later === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="coins"
                                      />
                                    </span>
                                    <span
                                      onClick={() =>
                                        this.onOpenModal(
                                          "moreInfoModal",
                                          0,
                                          'This booking is accepted "Book Now, Pay Later". You are able to hold your booking at the same fare and make payment later up to 72 hours, booking will be auto-released if no payment received within 72 hours.'
                                        )
                                      }
                                      className="content_text"
                                    >
                                      <a>Book Now, Pay Later</a>
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["gratuities"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="dollar-sign"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Gratuities
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["acf"] === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="money-bill-alt"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Agency Collection Fee (ACF)
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {tour.highlight === "" ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="info-circle"
                                      />
                                    </span>
                                    <span className="content_text red">
                                      {tour.highlight}
                                    </span>
                                  </div>
                                </Col>
                              )}
                              <Col className="gutter-row" md={12} lg={8}>
                                <div className="things_warp">
                                  <span className="info_icon">
                                    <FontAwesomeIcon
                                      fixedWidth
                                      icon="envelope"
                                    />
                                  </span>
                                  <span
                                    onClick={() =>
                                      this.onOpenModal(
                                        "latestInfoModal",
                                        0,
                                        tour.latest_tour_confirmation
                                      )
                                    }
                                    className="content_text"
                                  >
                                    <a>Latest Tour Confirmation</a>
                                  </span>
                                </div>
                              </Col>
                              <Col className="gutter-row" md={12} lg={8}>
                                <div className="things_warp">
                                  <span className="info_icon">
                                    <FontAwesomeIcon
                                      fixedWidth
                                      icon="question-circle"
                                    />
                                  </span>
                                  <span
                                    onClick={() =>
                                      this.onOpenModal(
                                        "moreInfoModal",
                                        0,
                                        dataList.description
                                      )
                                    }
                                    className="content_text"
                                  >
                                    <a>More</a>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}
                      {/**************************** Tour Information ****************************/}
                      {homepage_new.suka === "true" && (
                        <Card className="things">
                          <div className="quick-info">
                            <h3 className="title">Tour Information</h3>
                            <Row className="things_to_know" gutter={2}>
                              {this.state.fare_type === "deposit" ? (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon fixedWidth icon="male" />
                                    </span>
                                    <span className="content_text">
                                      Deposit per pax{" "}
                                      <strong>
                                        {dataList.price_currency}{" "}
                                        {this.priceFormat(tour.deposit)}
                                      </strong>
                                    </span>
                                  </div>
                                </Col>
                              ) : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="hand-holding-usd"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Full Payment Required
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {/*{
                            tour.final_payment_date === "" ? (
                              null
                            ) : (
                              <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon"><FontAwesomeIcon fixedWidth icon="clock" /></span>
                                    <span className="content_text">
                                      <span className="cutoff">Cut Off :</span>
                                      {moment(tour.final_payment_date,"DD-MM-YYYY").format(dateFormat)}
                                    </span>
                                  </div>
                              </Col>
                              )
                          }*/}
                              {tour.guaranteed_departure === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <Tag
                                        className="guaranteed_tag"
                                        color="green"
                                      >
                                        G
                                      </Tag>
                                    </span>
                                    <span className="content_text"></span>
                                    Guaranteed Departure
                                  </div>
                                </Col>
                              )}
                              {dataList.file_url === "" ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="clipboard-list"
                                      />
                                    </span>
                                    Download Itinerary
                                    <span className="content_text">
                                      <a
                                        href={dataList.file_url}
                                        target="_blank"
                                      >
                                        {" "}
                                        Here
                                      </a>
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["group_departure"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="users"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Group Departure
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {tour.pay_later === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="coins"
                                      />
                                    </span>
                                    <span
                                      onClick={() =>
                                        this.onOpenModal(
                                          "moreInfoModal",
                                          0,
                                          'This booking is accepted "Book Now, Pay Later". You are able to hold your booking at the same fare and make payment later up to 72 hours, booking will be auto-released if no payment received within 72 hours.'
                                        )
                                      }
                                      className="content_text"
                                    >
                                      <a>Book Now, Pay Later</a>
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {tour.highlight === "" ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="info-circle"
                                      />
                                    </span>
                                    <span className="content_text">
                                      {tour.highlight}
                                    </span>
                                  </div>
                                </Col>
                              )}
                              <Col className="gutter-row" md={12} lg={8}>
                                <div className="things_warp">
                                  <span className="info_icon">
                                    <FontAwesomeIcon
                                      fixedWidth
                                      icon="envelope"
                                    />
                                  </span>
                                  <span
                                    onClick={() =>
                                      this.onOpenModal(
                                        "latestInfoModal",
                                        0,
                                        tour.latest_tour_confirmation
                                      )
                                    }
                                    className="content_text"
                                  >
                                    <a>Latest Tour Confirmation</a>
                                  </span>
                                </div>
                              </Col>
                              <Col className="gutter-row" md={12} lg={8}>
                                <div className="things_warp">
                                  <span className="info_icon">
                                    <FontAwesomeIcon
                                      fixedWidth
                                      icon="question-circle"
                                    />
                                  </span>
                                  <span
                                    onClick={() =>
                                      this.onOpenModal(
                                        "moreInfoModal",
                                        0,
                                        dataList.description
                                      )
                                    }
                                    className="content_text"
                                  >
                                    <a>Tour Program</a>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}
                      {/**************************** Tour Inclusive ****************************/}
                      {homepage_new.suka === "true" && (
                        <Card className="things">
                          <div className="quick-info">
                            <h3 className="title">Tour Inclusive</h3>
                            <Row className="things_to_know" gutter={2}>
                              {tour.insurance === "" ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="user-shield"
                                      />
                                    </span>
                                    <span className="content_text">
                                      {tour.insurance}
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["airport_taxes"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="receipt"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Airport Taxes
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["tour_leader"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="user-check"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Tour Leader
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["luggage"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="shopping-bag"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Check-in baggage
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {tour.red_luggage_protection === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="suitcase"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Red Luggage Protection
                                    </span>
                                    <span
                                      onClick={() =>
                                        this.onOpenModal(
                                          "redLuggageInfoModal",
                                          0,
                                          red_luggage
                                        )
                                      }
                                      className="content_text"
                                    >
                                      <a>
                                        <FontAwesomeIcon
                                          fixedWidth
                                          icon="info-circle"
                                        />
                                      </a>
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["wifi"] === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon fixedWidth icon="wifi" />
                                    </span>
                                    <span className="content_text">
                                      Share Wifi
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["meal_onboard"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="utensils"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Meal Onboard
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["hotel"] === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon fixedWidth icon="bed" />
                                    </span>
                                    <span className="content_text">Hotel</span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["gratuities"] ===
                              false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="dollar-sign"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Gratuities
                                    </span>
                                  </div>
                                </Col>
                              )}
                              {dataList.includings["acf"] === false ? null : (
                                <Col className="gutter-row" md={12} lg={8}>
                                  <div className="things_warp">
                                    <span className="info_icon">
                                      <FontAwesomeIcon
                                        fixedWidth
                                        icon="money-bill-alt"
                                      />
                                    </span>
                                    <span className="content_text">
                                      Agency Collection Fee (ACF)
                                    </span>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                        </Card>
                      )}

                      {/**************************** Remark ****************************/}
                      {dataList.remark === "" ||
                      dataList.remark === undefined ? null : (
                        <Card className="things">
                          <div className="quick-info">
                            <h3 className="title">Remark</h3>
                            <Row className="things_to_know" gutter={2}>
                              <Col className="gutter-row" md={12} lg={8}>
                                <p>{dataList.remark}</p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}

                      {/**************************** flight infor ****************************/}
                      {tour.flights &&
                        tour.flights.map((flight, i) => {
                          return (
                            <Card className="flight_infor" key={i}>
                              <h3 className="title">
                                <img src={flightImg} alt="img" />
                                <span>{flight.from_airport}</span>
                                <FontAwesomeIcon
                                  icon="long-arrow-alt-right"
                                  className="left_arrow"
                                />
                                <span>{flight.to_airport}</span>
                              </h3>
                              {/* <p className="date">{moment(flight.departure_date,"DD-MM-YYYY").format("dddd, " + dateFormat)}</p> */}
                              <div className="details">
                                <div className="logo">
                                  <img
                                    src={
                                      flight.airline_logo !== ""
                                        ? flight.airline_logo
                                        : this.props.defaultImage
                                    }
                                    alt="img"
                                  />
                                </div>
                                <div className="content">
                                  <p className="airline">
                                    {flight.airline}
                                    <span>{flight.flight_no}</span>
                                  </p>
                                  <p className="type"></p>
                                  <div className="time">
                                    <div>
                                      <p className="time_style">{`${moment(
                                        flight.departure_date,
                                        "DD-MM-YYYY"
                                      ).format("ddd, " + dateFormat)}, ${
                                        flight.departure_time
                                      }`}</p>
                                      <p className="country">
                                        {flight.from_airport}
                                      </p>
                                    </div>
                                    <div>
                                      <FontAwesomeIcon
                                        icon="long-arrow-alt-right"
                                        className="left_arrow"
                                      />
                                    </div>
                                    <div>
                                      <p className="time_style">{`${moment(
                                        flight.arrival_date,
                                        "DD-MM-YYYY"
                                      ).format("ddd, " + dateFormat)}, ${
                                        flight.arrival_time
                                      }`}</p>
                                      <p className="country">
                                        {flight.to_airport}
                                      </p>
                                    </div>
                                    <p className="remark">{flight.remark}</p>
                                    <div className="clear"></div>
                                  </div>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </Card>
                          );
                        })}

                      {/**************************** Booking ****************************/}
                      <div className="header_title">
                        <h2>Your Booking</h2>
                      </div>
                      <Card title="Booking Selection" className="booking">
                        <p className="title_sub">Price Details</p>
                        <PriceDetails
                          columns={price_columns}
                          data={price_datas}
                          dtaTooltip={bookable && discount_available}
                        />

                        {this.props.bookable && (
                          <>
                            <Divider />
                            <div className="form_booking">
                              <Form.Item label="No of Pax">
                                {getFieldDecorator("quantity", {
                                  setFieldsValue:
                                    passengers &&
                                    passengers.filter(
                                      (item) => item.visible === true
                                    ).length,
                                  rules: [
                                    {
                                      required: true,
                                      message: "*Please select no of pax",
                                    },
                                  ],
                                })(
                                  <Select
                                    placeholder="Select"
                                    onChange={(value) =>
                                      this.onChangeData(0, "quantity", value)
                                    }
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                  >
                                    {Array.from(
                                      Array(max_booking_seats),
                                      (e, i) => {
                                        let quantity = i + 1;
                                        return (
                                          <Option
                                            key={quantity}
                                            value={quantity}
                                          >
                                            {quantity} pax
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                            <div className="form_booking">
                              <Form.Item label="Sales person">
                                {getFieldDecorator("sub_sale_rep", {
                                  rules: [
                                    {
                                      required: true,
                                      pattern: new RegExp(
                                        "^[A-Za-z]{1}[A-Za-z0-9\\s\\-\\.]{2,}$"
                                      ),
                                      message:
                                        "*Please fill in the sales person",
                                    },
                                  ],
                                })(
                                  <Input
                                    /* className={validationErrors.sub_sale_rep ? 'error_border' : ''} */ onChange={(
                                      event
                                    ) =>
                                      this.onChangeData(
                                        0,
                                        "sub_sale_rep",
                                        event.target.value
                                      )
                                    }
                                    placeholder="Sales person"
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </>
                        )}

                        <div className="clear"></div>
                      </Card>
                    </TabPane>
                  );
                })}
            </Tabs>
            {this.props.bookable && (
              <>
                {/**************************** Passenger ****************************/}
                <div className="header_title">
                  <h2>Passenger Info</h2>
                </div>

                <Card className="passenger">
                  {passengers.map((passenger, passenger_index) => {
                    return passenger.visible === true ? (
                      <div className="passenger_warp" key={passenger_index}>
                        <div className="form_passenger ">
                          <PageHeader
                            tags={
                              <Tag color="#1890ff">
                                Passenger {passenger_index + 1}
                              </Tag>
                            }
                          >
                            <Row type="flex">
                              <div className="form_control pandding_right">
                                <Form.Item label="Designation">
                                  {getFieldDecorator(
                                    "designation" + passenger_index,
                                    {
                                      initialValue: "Mr",
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            "*Please select the designation",
                                        },
                                      ],
                                    }
                                  )(
                                    <Select
                                      value={
                                        this.state.passengers[passenger_index][
                                          "designation"
                                        ] !== ""
                                          ? this.state.passengers[
                                              passenger_index
                                            ]["designation"]
                                          : ""
                                      }
                                      /* className={validationErrors.designation ? 'error_border' : ''} */ placeholder="Select designation"
                                      optionFilterProp="children"
                                      onChange={(value) =>
                                        this.onChangeData(
                                          passenger_index,
                                          "designation",
                                          value
                                        )
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {designations &&
                                        designations.map((items, i) => {
                                          return (
                                            <Option key={i} value={items}>
                                              {items}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>

                              <div className="form_control pandding_left">
                                <Form.Item label={"Name on " + documentType}>
                                  {getFieldDecorator("name" + passenger_index, {
                                    rules: [
                                      {
                                        required: true,
                                        message: "*Please fill in the name",
                                      },
                                    ],
                                  })(
                                    <Input
                                      setfieldsvalue={
                                        this.state.passengers[passenger_index][
                                          "name"
                                        ] !== ""
                                          ? this.state.passengers[
                                              passenger_index
                                            ]["name"]
                                          : ""
                                      }
                                      /* className={validationErrors.name ? 'error_border' : ''} */ onChange={(
                                        event
                                      ) =>
                                        this.onChangeData(
                                          passenger_index,
                                          "name",
                                          event.target.value
                                        )
                                      }
                                      placeholder={documentType + " name"}
                                    />
                                  )}
                                </Form.Item>
                              </div>

                              <div className="form_control pandding_right">
                                <Form.Item label="Date of birth">
                                  {getFieldDecorator(
                                    "date_of_birth" + passenger_index,
                                    {
                                      setFieldsValue:
                                        this.state.passengers[passenger_index][
                                          "date_of_birth"
                                        ] !== ""
                                          ? moment(
                                              this.state.passengers[
                                                passenger_index
                                              ]["date_of_birth"]
                                            )
                                          : "",
                                      rules: [
                                        {
                                          required:
                                            homepage_new.suka === "true",
                                          message:
                                            "*Please select the date of birth",
                                        },
                                      ],
                                    }
                                  )(
                                    <DatePicker
                                      defaultPickerValue={moment().add(
                                        -2,
                                        "year"
                                      )}
                                      disabledDate={(current) => {
                                        return (
                                          current >
                                            moment()
                                              .add(-2, "year")
                                              .startOf("day") ||
                                          current <
                                            moment()
                                              .add(-100, "year")
                                              .startOf("day") ||
                                          current >
                                            moment(
                                              dataList &&
                                                dataList.tours[
                                                  this.props.tourKey
                                                ].departure_date,
                                              "DD/MM/YYYY"
                                            ).toDate()
                                        );
                                      }}
                                      /* className={validationErrors.date_of_birth ? 'error_border' : ''} */ onChange={(
                                        value
                                      ) =>
                                        this.onChangeData(
                                          passenger_index,
                                          "date_of_birth",
                                          moment(value).format("YYYY/MM/DD")
                                        )
                                      }
                                      placeholder="Select date of birth"
                                    />
                                  )}
                                </Form.Item>
                              </div>

                              <div className="form_control pandding_left">
                                <Form.Item label={<div>Mobile number<span style={{ color: "red" }}> (eg. +601234567890)</span></div>}>
                                  {getFieldDecorator(
                                    "mobile" + passenger_index,
                                    {
                                      initialValue:
                                        this.state.passengers[passenger_index][
                                          "mobile"
                                        ] !== ""
                                          ? moment(
                                              this.state.passengers[
                                                passenger_index
                                              ]["mobile"]
                                            )
                                          : "",
                                      rules: [
                                        {
                                          pattern: /^(?:\+[1-9]\d{0,2})?([0-9\-]{8,14})$/,
                                          message:
                                            "Invalid phone number. Must be in format +[country_code][number] (eg. +601234567890)",
                                        },
                                      ],
                                      /*rules: [{ required: passenger_index == 0 ? true : false, message: '*Please fill in the mobile number', }],*/
                                    }
                                  )(
                                    <Input
                                      setfieldsvalue={
                                        this.state.passengers[passenger_index][
                                          "mobile"
                                        ] !== ""
                                          ? this.state.passengers[
                                              passenger_index
                                            ]["mobile"]
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.onChangeData(
                                          passenger_index,
                                          "mobile",
                                          event.target.value
                                        )
                                      }
                                       placeholder="+[country_code][number] (e.g., +601234567890)"
                                    />
                                  )}
                                </Form.Item>
                              </div>
                              {/** 13 June 2024 commented out by Stefan, to be reopened once RSVN ready */
                              /** ===================================================== */}
                              {/* <div className="form_control pandding_right">
                                <Form.Item label="Is Malaysian?">
                                  {getFieldDecorator(
                                    "is_malaysian" + passenger_index,
                                    {
                                      rules: [{ required: false }],
                                    }
                                  )(
                                    <>
                                      <Select
                                        defaultValue={true}
                                        onChange={(value) =>
                                          this.onChangeData(
                                            passenger_index,
                                            "is_malaysian",
                                            value
                                          )
                                        }
                                      >
                                        <Option value={false}>
                                          Not Malaysian
                                        </Option>
                                        <Option value={true}>Malaysian</Option>
                                      </Select>
                                    </>
                                  )}
                                </Form.Item>
                              </div>

                              <div className="form_control pandding_left">
                                <Form.Item label="NRIC">
                                  {getFieldDecorator("nric" + passenger_index, {
                                    rules: [
                                      {
                                        required: false,
                                        message: "*Please fill in the NRIC",
                                      },
                                    ],
                                  })(
                                    <div>
                                      <Input
                                        value={
                                          this.state.passengers[
                                            passenger_index
                                          ]["nric"]
                                        }
                                        maxLength={12}
                                        disabled={
                                          !this.state.passengers[
                                            passenger_index
                                          ]["is_malaysian"]
                                        }
                                        setfieldsvalue={
                                          this.state.passengers[
                                            passenger_index
                                          ]["nric"] !== ""
                                            ? this.state.passengers[
                                                passenger_index
                                              ]["nric"]
                                            : ""
                                        }
                                        onChange={(e) =>
                                          this.onChangeData(
                                            passenger_index,
                                            "nric",
                                            e.target.value
                                          )
                                        }
                                        placeholder="XXXXXXXXXXXXX 12 digits"
                                      />
                                    </div>
                                  )}
                                </Form.Item>
                              </div> */}
                              {/** ===================================================== */}

                              <div className="form_control pandding_right">
                                <Form.Item
                                  label={
                                    homepage_new.suka === "true" &&
                                    this.state.is_cruise
                                      ? "Passport number / IC"
                                      : "Passport number"
                                  }
                                >
                                  {getFieldDecorator(
                                    "passport_number" + passenger_index,
                                    {
                                      rules: [
                                        {
                                          required: false,
                                          message:
                                            "*Please fill in the passport number",
                                        },
                                      ],
                                    }
                                  )(
                                    <Input
                                      setfieldsvalue={
                                        this.state.passengers[passenger_index][
                                          "passport_number"
                                        ] !== ""
                                          ? this.state.passengers[
                                              passenger_index
                                            ]["passport_number"]
                                          : ""
                                      }
                                      /* className={validationErrors.passport_number ? 'error_border' : ''} */ onChange={(
                                        event
                                      ) =>
                                        this.onChangeData(
                                          passenger_index,
                                          "passport_number",
                                          event.target.value
                                        )
                                      }
                                      placeholder="Passport number"
                                    />
                                  )}
                                </Form.Item>
                              </div>

                              <div className="form_control pandding_left">
                                <Form.Item label="Category">
                                  {getFieldDecorator(
                                    "category" + passenger_index,
                                    {
                                      setFieldsValue:
                                        this.state.passengers[passenger_index][
                                          "category"
                                        ],
                                      rules: [
                                        {
                                          required: true,
                                          message: "*Please select category",
                                        },
                                      ],
                                    }
                                  )(
                                    <Select
                                      placeholder="Select"
                                      optionFilterProp="children"
                                      onChange={(value) =>
                                        this.onChangeData(
                                          passenger_index,
                                          "category",
                                          value
                                        )
                                      }
                                      onFocus={onFocus}
                                      onBlur={onBlur}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {passenger &&
                                        passenger.price_types.map((item, i) => {
                                          return (
                                            <Option
                                              disabled={!item.visible}
                                              key={i}
                                              value={item.name}
                                            >
                                              {item.name === "adult"
                                                ? this.getAdultName()
                                                : item.name === "child_with_bed"
                                                ? "Child with extra bed"
                                                : item.name ===
                                                  "single_supplement"
                                                ? this.getSingleName()
                                                : item.name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  item.name
                                                    .substr(1)
                                                    .toLowerCase()
                                                    .replace(/_/g, " ")}{" "}
                                              ({dataList.price_currency}{" "}
                                              {this.priceFormat(item.price)})
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>

                              <div className="form_control pandding_right">
                                <Form.Item label="Remark">
                                  {getFieldDecorator(
                                    "remark" + passenger_index
                                  )(
                                    <TextArea
                                      // value={passenger.remark}
                                      setfieldsvalue={
                                        this.state.passengers[passenger_index][
                                          "remark"
                                        ] !== ""
                                          ? this.state.passengers[
                                              passenger_index
                                            ]["remark"]
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.onChangeData(
                                          passenger_index,
                                          "remark",
                                          event.target.value
                                        )
                                      }
                                      placeholder="Remark VGML - vegetarian"
                                      autoSize={{ minRows: 1, maxRows: 3 }}
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </Row>
                            <Row type="flex">
                              {passenger.showInfantLink === true ? (
                                <div className="form_control pandding_left">
                                  <span>
                                    <a
                                      onClick={(e) =>
                                        this.addInfants(passenger_index)
                                      }
                                    >
                                      Add Infant
                                    </a>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}

                              {passenger.showInfants === true
                                ? passenger.infants.map((items, i) => {
                                    return (
                                      <div className="infants" key={i}>
                                        <p className="title">
                                          <Tag color="red">Infant {i + 1}</Tag>
                                          <span
                                            className="plus"
                                            onClick={(e) =>
                                              this.removeInfants(
                                                passenger_index,
                                                i
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon="times"
                                              className="left_arrow remove_icon"
                                            />
                                          </span>
                                        </p>
                                        <div className="form_control pandding_right">
                                          <Form.Item label={"Name on passport"}>
                                            {getFieldDecorator(
                                              i +
                                                "infant_name" +
                                                passenger_index,
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "*Please fill in the name",
                                                  },
                                                ],
                                              }
                                            )(
                                              <Input
                                                setfieldsvalue={
                                                  items["name"] !== ""
                                                    ? items["name"]
                                                    : ""
                                                }
                                                onChange={(event) =>
                                                  this.onChangeDataInfant(
                                                    passenger_index,
                                                    i,
                                                    "name",
                                                    event.target.value
                                                  )
                                                }
                                                placeholder="Infants name"
                                              />
                                            )}
                                          </Form.Item>
                                        </div>

                                        <div className="form_control pandding_left">
                                          <Form.Item label="Date of birth">
                                            {getFieldDecorator(
                                              i +
                                                "date_of_birth" +
                                                passenger_index,
                                              {
                                                setFieldsValue:
                                                  items["date_of_birth"] !== ""
                                                    ? moment(
                                                        items["date_of_birth"]
                                                      )
                                                    : "",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "*Please select the date of birth",
                                                  },
                                                ],
                                              }
                                            )(
                                              <DatePicker
                                                defaultPickerValue={moment()}
                                                disabledDate={(current) => {
                                                  return (
                                                    current >
                                                      moment().startOf("day") ||
                                                    current <
                                                      moment()
                                                        .add(-2, "year")
                                                        .startOf("day") ||
                                                    current >
                                                      moment(
                                                        dataList &&
                                                          dataList.tours[
                                                            this.props.tourKey
                                                          ].departure_date,
                                                        "DD/MM/YYYY"
                                                      ).toDate()
                                                  );
                                                }}
                                                onChange={(value) =>
                                                  this.onChangeDataInfant(
                                                    passenger_index,
                                                    i,
                                                    "date_of_birth",
                                                    moment(value).format(
                                                      "YYYY/MM/DD"
                                                    )
                                                  )
                                                }
                                                placeholder="Select date of birth"
                                              />
                                            )}
                                          </Form.Item>
                                        </div>
                                        <div className="form_control pandding_right">
                                          <Form.Item label="Gender">
                                            {getFieldDecorator(
                                              i + "gender" + passenger_index,
                                              {
                                                initialValue: "",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "*Please select the gender",
                                                  },
                                                ],
                                              }
                                            )(
                                              <Select
                                                value={
                                                  items["gender"] !== ""
                                                    ? items["gender"]
                                                    : ""
                                                }
                                                placeholder="Select gender"
                                                optionFilterProp="children"
                                                onChange={(value) =>
                                                  this.onChangeDataInfant(
                                                    passenger_index,
                                                    i,
                                                    "designation",
                                                    value
                                                  )
                                                }
                                                filterOption={(input, option) =>
                                                  option.props.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                      input.toLowerCase()
                                                    ) >= 0
                                                }
                                              >
                                                {["Female", "Male"].map(
                                                  (items, i) => {
                                                    return (
                                                      <Option
                                                        key={i}
                                                        value={items.toLowerCase()}
                                                      >
                                                        {items}
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            )}
                                          </Form.Item>
                                        </div>
                                        {/** 13 June 2024 commented out by Stefan, to be reopened once RSVN ready */
                                        /** ===================================================== */}
                                        {/* <div className="form_control pandding_left">
                                          <Form.Item label="Is Malaysian?">
                                            {getFieldDecorator(
                                              i +
                                                "is_malaysian" +
                                                passenger_index,
                                              {
                                                rules: [
                                                  {
                                                    required: false,
                                                    message:
                                                      "*Please fill in the NRIC",
                                                  },
                                                ],
                                              }
                                            )(
                                              <>
                                                <Select
                                                  defaultValue={true}
                                                  onChange={(value) =>
                                                    this.onChangeDataInfant(
                                                      passenger_index,
                                                      i,
                                                      "is_malaysian",
                                                      value
                                                    )
                                                  }
                                                >
                                                  <Option value={false}>
                                                    Not Malaysian
                                                  </Option>
                                                  <Option value={true}>
                                                    Malaysian
                                                  </Option>
                                                </Select>
                                              </>
                                            )}
                                          </Form.Item>
                                        </div>
                                        <div className="form_control pandding_right">
                                          <Form.Item label="NRIC">
                                            {getFieldDecorator(
                                              i + "nric" + passenger_index,
                                              {
                                                rules: [
                                                  {
                                                    required: false,
                                                    message:
                                                      "*Please fill in the NRIC",
                                                  },
                                                ],
                                              }
                                            )(
                                              <div>
                                                <Input
                                                  value={items["nric"]}
                                                  maxLength={12}
                                                  disabled={
                                                    !items["is_malaysian"]
                                                  }
                                                  setfieldsvalue={
                                                    items["nric"] !== ""
                                                      ? items["nric"]
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    this.onChangeDataInfant(
                                                      passenger_index,
                                                      i,
                                                      "nric",
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="XXXXXXXXXXXXX 12 digits"
                                                />
                                              </div>
                                            )}
                                          </Form.Item>
                                        </div> */}
                                        {/** ===================================================== */}
                                        <div className="clear"></div>
                                      </div>
                                    );
                                  })
                                : null}

                              {passenger.showInfants === true &&
                              passenger.infants.length < 2 ? (
                                <div className="infants">
                                  <p className="title">
                                    <Tag color="red">Infant</Tag>
                                    <span
                                      className="plus"
                                      onClick={(e) =>
                                        this.addInfants(passenger_index)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="plus"
                                        className="left_arrow add_icon"
                                      />
                                    </span>
                                  </p>
                                  <br />
                                </div>
                              ) : (
                                ""
                              )}
                            </Row>
                          </PageHeader>
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                  })}
                  {homepage_new.suka === "true" && (
                    <div className="insuran_detail">
                      {/**************************** Suka Travel Insurance **************************************/}
                      <div className="free_chubb">
                        <h3 className="title2">Travel Insurance</h3>
                        <p>
                          Travel insurance is mandatory as advised by Malaysian
                          Travel & Tour Association (MATTA). Suka Travel & Tours
                          strongly advise all Tour Members to purchase their
                          travel insurance coverage for the duration of their
                          tour. Our employees would be more than happy to assist
                          in offering the available plans for your selection.
                        </p>
                        {getFieldDecorator("insurance_confirmation", {
                          initialValue: null,
                          rules: [
                            {
                              required: true,
                              message: "*Please select no of pax",
                            },
                          ],
                        })(
                          <Radio.Group
                            onChange={(e) =>
                              this.handleSukaInsurance(e.target.value)
                            }
                          >
                            <Radio value={true}>
                              Yes, we would like to buy the travel insurance
                              from Suka Travel.
                            </Radio>
                            <Radio value={false}>
                              No, we will buy the travel insurance ourselves and
                              indemnify Suka Travel & Tours for any costs
                              incurred by them due to our failure to take out
                              adequate insurance cover.
                            </Radio>
                          </Radio.Group>
                        )}
                        <p className="error">
                          {validationErrors.suka_insurance}
                        </p>
                      </div>
                    </div>
                  )}
                </Card>

                {/**************************** Payment Method ****************************/}
                {payable && (
                  <div>
                    <div className="header_title">
                      <h2>Your Payment Method</h2>
                    </div>
                    <PaymentMethod
                      handlePaymentChange={this.handlePaymentChange.bind(this)}
                      credit_disabled={this.props.credit_disabled}
                      fpx_disabled={false}
                      paylater_disabled={
                        dataList.tours &&
                        !dataList.tours[this.props.tourKey].pay_later
                      }
                      paymentTypeAvailable={4}
                      payment_type={payment_type}
                      payment_method={payment_method}
                      bank_type={bank_type}
                      credits={this.props.credits}
                      method_visible={method_visible}
                      validationErrors={validationErrors}
                      banklist_select={banklist_select}
                      offline_banklist={offline_banklist}
                      handlePaymentMethodChange={(value, option) =>
                        this.handlePaymentMethodChange(value, option)
                      }
                      onChangePaymentCheckBox={(e) =>
                        this.onChangePaymentCheckBox(e)
                      }
                      terms={homepage_new.series_terms}
                      service_fee={this.props.service_fee}
                      final_price={this.props.final_price}
                      instant_access={!this.props.instant_access_series}
                      pageName="SeriesShowPage"
                      fpxb2b_disabled={
                        dataList.tours &&
                        !dataList.tours[this.props.tourKey].fpxb2b_available
                      }
                      dobw_disabled={
                        dataList.tours &&
                        !dataList.tours[this.props.tourKey].dobw_available
                      }
                      fpxb2c_disabled={
                        dataList.tours &&
                        !dataList.tours[this.props.tourKey].fpxb2c_available
                      }
                      fare_type={this.state.fare_type}
                      extra_deposit={extra_deposit}
                      allow_customer_pay={allow_customer_pay}
                      onChangeData={this.onChangeData.bind(this)}
                      fare_type_selected={this.state.fare_type_selected}
                      allow_fpxb2c_overlimit={true}
                      deposit={this.props.deposit}
                    />
                    <div className="after_select">
                      <Button
                        htmlType="submit"
                        disabled={this.state.check_out_disabled}
                        /* onClick={this.onConfirmBooking} */ type="primary"
                      >
                        CHECK OUT
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Form>

          <Modal
            title="Important Notice"
            style={{ top: 20 }}
            width={720}
            visible={bookingConfirmModal}
            closable={false}
            footer={[
              <Button
                key="no"
                onClick={() => this.onCloseModal("bookingConfirmModal")}
              >
                No
              </Button>,
              <Button
                key="yes"
                type="primary"
                loading={confirmLoading}
                /* disabled={confirmCheck['checkbox1'] == true && confirmCheck['checkbox2'] == true ? false : true} */ onClick={
                  this.submitForm
                }
                disabled={this.state.confirm_button_disabled}
              >
                Yes
              </Button>,
            ]}
          >
            <h3>
              <strong>Attention</strong>
            </h3>
            {dataList.tours &&
            dataList.tours[this.props.tourKey].pay_later &&
            this.state.payment_type == "pay_later" ? (
              <React.Fragment>
                {booking_release_info}
                {!this.props.dataList.auto_cancel ? (
                  <p>Your booking has been secured at the time of booking.</p>
                ) : (
                  <p>
                    Please note that once you have opted for the Book Now, Pay
                    Later option, your booking will be secured up to{" "}
                    {homepage_new.suka === "false" ? "72 hours" : "5 days"} from
                    the time of booking.{" "}
                  </p>
                )}
                <p>
                  Deposit needed latest by{" "}
                  {homepage_new.suka === "false"
                    ? moment().add(3, "days").format("DD MMM YYYY")
                    : moment().add(5, "days").format("DD MMM YYYY")}
                  :{" "}
                  <strong>
                    {dataList.price_currency}{" "}
                    {this.priceFormat(this.props.deposit)}
                  </strong>
                </p>
                {/*<p>
                  Balance payment required before{" "}
                  <strong>
                    {moment(
                      dataList &&
                        dataList.tours[this.props.tourKey].cut_off_date,
                      "DD/MM/YYYY"
                    ).format("DD/MM/YYYY")}
                  </strong>
                </p>*/}

                {/* <p>A deposit of <strong>RM {dataList && dataList.tours[this.props.tourKey].deposit}/Pax</strong> will be collected. A deposit does not constitute confirmation of the tour.</p>
                <p>All are subjected to a minimum group size (as determined by the company), in order for the confirmation to be affected for the departure to be finalized. </p> */}
              </React.Fragment>
            ) : this.state.fare_type == "deposit" &&
              this.state.payment_type == "credit_transaction" ? (
              <React.Fragment>
                {booking_release_info}
                <p>
                  Deposit payment {this.state.payment_method} of{" "}
                  <strong>
                    {dataList.price_currency} {this.priceFormat(final_deposit)}
                  </strong>{" "}
                  is require for this booking.
                </p>
                {/*<p>
                  Balance payment required by{" "}
                  <strong>
                    {moment(
                      dataList &&
                        dataList.tours[this.props.tourKey].cut_off_date,
                      "DD/MM/YYYY"
                    ).format("DD/MM/YYYY")}
                  </strong>
                </p>*/}
              </React.Fragment>
            ) : this.state.fare_type == "deposit" &&
              this.state.fare_type_selected != "" &&
              this.state.fare_type_selected.includes("deposit") ? (
              <React.Fragment>
                {booking_release_info}
                <p>
                  Deposit payment {this.state.payment_method} of{" "}
                  <strong>
                    {dataList.price_currency} {this.priceFormat(final_deposit)}
                  </strong>{" "}
                  is require for this booking.
                </p>
                {/*<p>
                  Balance payment required by{" "}
                  <strong>
                    {moment(
                      dataList &&
                        dataList.tours[this.props.tourKey].cut_off_date,
                      "DD/MM/YYYY"
                    ).format("DD/MM/YYYY")}
                  </strong>
                </p>*/}
                {/* <p>A deposit of <strong>RM {dataList && dataList.tours[this.props.tourKey].deposit}/Pax</strong> will be collected. A deposit does not constitute confirmation of the tour.</p>
                <p>All are subjected to a minimum group size (as determined by the company), in order for the confirmation to be affected for the departure to be finalized. </p> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {booking_release_info}
                <p>Full payment is required for this booking.</p>
                {/*<p>
                  Balance payment required by{" "}
                  <strong>
                    {moment(
                      dataList &&
                        dataList.tours[this.props.tourKey].cut_off_date,
                      "DD/MM/YYYY"
                    ).format("DD/MM/YYYY")}
                  </strong>
                </p>*/}
                {/*<p>A full payment of <strong>RM {this.props.total}</strong> will be collected. This is a guaranteed departure tour.</p>*/}
              </React.Fragment>
            )}

            <p>Agreed and confirm to submit this booking transaction.</p>
          </Modal>

          <Modal
            title={
              <React.Fragment>
                <FontAwesomeIcon fixedWidth icon="question-circle" />
                &nbsp; More
              </React.Fragment>
            }
            closable={false}
            visible={this.state.moreInfoModal}
            footer={[
              <Button
                type="primary"
                key="close"
                onClick={() => this.onCloseModal("moreInfoModal")}
              >
                Close
              </Button>,
            ]}
          >
            <p>
              <ReactMarkdown
                source={this.state.moreInfoModalcontent}
                escapeHtml={false}
              />
            </p>
          </Modal>

          <Modal
            title={
              <React.Fragment>
                <FontAwesomeIcon fixedWidth icon="user-shield" />
                &nbsp; Free Travel Insurance
              </React.Fragment>
            }
            closable={false}
            visible={this.state.freeTravelInsuranceModal}
            footer={[
              <Button
                type="primary"
                key="close"
                onClick={() => this.onCloseModal("freeTravelInsuranceModal")}
              >
                Close
              </Button>,
            ]}
          >
            <div>
              <p>
                Effective from <b>15 July 2024</b>, all travel insurance must
                activate in the TravelB2B platfrom within 30 days from the
                schedule departure date to entitle complimentary travel
                insurance. Please refer memo for details
              </p>
            </div>

            <p>
              <ReactMarkdown
                source={this.state.freeTravelInsuranceModalContent}
                escapeHtml={false}
              />
            </p>
          </Modal>

          <Modal
            title={
              <React.Fragment>
                <FontAwesomeIcon fixedWidth icon="envelope" />
                &nbsp; Latest Tour Confirmation
              </React.Fragment>
            }
            closable={false}
            visible={this.state.latestInfoModal}
            footer={[
              <Button
                type="primary"
                key="close"
                onClick={() => this.onCloseModal("latestInfoModal")}
              >
                Close
              </Button>,
            ]}
          >
            {this.state.latestInfoModalcontent == "" ? (
              <Spin />
            ) : (
              <div>
                <p>
                  Assembly Time :{" "}
                  {this.state.latestInfoModalcontent.assembly_time}
                </p>
                <p>
                  Meet Up Point :{" "}
                  {this.state.latestInfoModalcontent.meet_up_point}
                </p>
                {homepage_new.suka === "false" && (
                  <p>
                    Tour Guide Name :{" "}
                    {this.state.latestInfoModalcontent.tour_guide_name}
                  </p>
                )}
                {homepage_new.suka === "false" && (
                  <p>
                    Tour Guide Chinese Name :{" "}
                    {this.state.latestInfoModalcontent.tour_guide_chinese_name}
                  </p>
                )}
                {homepage_new.suka === "false" && (
                  <p>
                    Tour Guide Phone No. :{" "}
                    {this.state.latestInfoModalcontent.tour_guide_phone}
                  </p>
                )}
                <p>
                  Hand Carry Weight :{" "}
                  {this.state.latestInfoModalcontent.hand_carry_weight}
                </p>
                <p>
                  Luggage Weight :{" "}
                  {this.state.latestInfoModalcontent.luggage_weight}
                </p>
                <p>
                  Luggage Quantity :{" "}
                  {this.state.latestInfoModalcontent.luggage_quantity}
                </p>
                <p>
                  Emergency Contact :{" "}
                  {
                    this.state.latestInfoModalcontent
                      .confirmation_emergency_contact
                  }
                </p>
              </div>
            )}
            <p>
              <ReactMarkdown
                source={this.state.latestInfoModalcontent}
                escapeHtml={false}
              />
            </p>
          </Modal>

          <Modal
            title={
              <React.Fragment>
                <FontAwesomeIcon fixedWidth icon="question-circle" />
                &nbsp; Red Luggage Protection
              </React.Fragment>
            }
            closable={false}
            visible={this.state.redLuggageInfoModal}
            width={810}
            footer={[
              <Button
                type="primary"
                key="close"
                onClick={() => this.onCloseModal("redLuggageInfoModal")}
              >
                Close
              </Button>,
            ]}
          >
            {this.state.redLuggageInfoModalcontent == "" ? (
              <Spin />
            ) : (
              <img src={this.state.redLuggageInfoModalcontent}></img>
            )}
          </Modal>

          <Modal
            visible={bookingErrorModal}
            title={null}
            footer={null}
            closable={false}
            destroyOnClose={() => this.onCloseModal("bookingErrorModal")}
          >
            <Empty
              image={warning}
              imageStyle={{
                height: 100,
              }}
              description={<p className="popup_text">{bookingErrorMessage}</p>}
              className="popup_footer"
            >
              <Button
                type="primary"
                onClick={() => this.onCloseModal("bookingErrorModal")}
              >
                Close
              </Button>
            </Empty>
          </Modal>

          <Modal
            title={
              <React.Fragment>
                <FontAwesomeIcon
                  fixedWidth
                  icon="clipboard-list"
                  size="lg"
                  className="file_icon"
                />
                &nbsp; Itinerary
              </React.Fragment>
            }
            visible={this.state.itineraryModalVisible}
            width={page_width}
            maskClosable={false}
            onCancel={() => this.onCloseModal("itinerary_modal")}
            footer={[
              <Button
                type="primary"
                key="close"
                onClick={() => this.onCloseModal("itinerary_modal")}
              >
                Close
              </Button>,
            ]}
          >
            {this.state.itineraryModalContent == "" ? (
              <Spin />
            ) : (
              <Document
                file={this.state.itineraryModalContent}
                onLoadSuccess={this.onDocumentLoadSuccess}
                error={"There is no itinerary available."}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            )}
          </Modal>
        </div>
      </div>
    );
  }
}

const SeriesShowPage = Form.create({ name: "series" })(LeftPanel);
export default withRouter(SeriesShowPage);
